import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../utils/chairside.hooks';
import { useCreateTrainingIntakeSurveyResponse } from '../getting_started/components/TrainingDataIntake/hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import { BillingAndDeliveryStep } from './BillingAndDeliveryStep';
import { OnboardingHeader } from './OnboardingHeader';
import { PersonalizeTrainingStep } from './PersonalizeTrainingStep';
import { PracticeScreen } from '@orthly/dentin';
import { DEFAULT_TOAST_TIMEOUT, SidebarContainer, Toast } from '@orthly/ui';
import {
    Button,
    CheckCircleOutlineIcon,
    ClockAlertIcon,
    CreditCardIcon,
    FlossPalette,
    LockIcon,
    PracticeIcon,
    styled,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
});
const Body = styled('div')({
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    height: '100%',
    width: '100%',
    display: 'flex',
    overflowY: 'auto',
});
const StyledSidebarContainer = styled(SidebarContainer)({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    minWidth: '380px',
});
const Title = styled(Text)({
    padding: '32px 0px',
});
const Step = styled('div')({
    display: 'flex',
    gap: '16px',
    padding: '16px 0px',
});
const ButtonContainer = styled('div')({
    display: 'flex',
    padding: '24px 0px',
    justifyContent: 'space-between',
});
const StyledButton = styled(Button)({
    height: '32px',
});

const useProgressPercentage = ({ stepIndex, stepsLength }: { stepIndex: number; stepsLength: number }) => {
    const isChairside = useIsChairside();
    // Chairside include a step for setting up scanner
    const totalSteps = isChairside ? stepsLength + 1 : stepsLength;
    // Progress bar will show half way through current step to indicated the step is in progress
    const halfStepPercent = 1 / totalSteps / 2;
    return (stepIndex / totalSteps + halfStepPercent) * 100;
};

export const OnboardingNavigationLayout: React.VFC = () => {
    const [activeStepIndex, setActiveStepIndex] = React.useState(0);
    // TODO: These will be controlled by the backed once available
    const [billingIsComplete, _setBillingIsComplete] = React.useState(false);
    const [deliveryAddressComplete, _setDeliveryAddressComplete] = React.useState(false);

    const { errorToastOpen, setErrorToastOpen } = useCreateTrainingIntakeSurveyResponse();
    const history = useHistory();
    const isChairside = useIsChairside();
    const startScannerSetupOnboarding = usePortalToChairsideBridgeCommand('startScannerSetupOnboarding');

    const steps = [
        {
            title: 'Update billing & delivery info',
            subTitle: 'Set up your billing to process orders.',
            icon:
                billingIsComplete && deliveryAddressComplete ? (
                    <CheckCircleOutlineIcon color={'primary'} />
                ) : (
                    <CreditCardIcon color={activeStepIndex === 0 ? 'primary' : 'disabled'} />
                ),
            component: <BillingAndDeliveryStep />,
            saveAndContinueAction: () => setActiveStepIndex(1),
        },
        {
            title: 'Personalize your training (optional)',
            subTitle: 'Get Dandy working for your practice.',
            icon: activeStepIndex === 1 ? <PracticeIcon color={'primary'} /> : <LockIcon color={'disabled'} />,
            component: <PersonalizeTrainingStep />,
            previousAction: () => {
                setActiveStepIndex(0);
            },
            saveAndContinueAction: () => {
                isChairside ? startScannerSetupOnboarding?.() : history.push(PracticeScreen.inbox);
            },
        },
    ];
    const percentComplete = useProgressPercentage({ stepIndex: activeStepIndex, stepsLength: steps.length });

    return (
        <Container>
            <OnboardingHeader showProgressBar percentComplete={percentComplete} />

            <Body>
                <StyledSidebarContainer position={'left'}>
                    <div>
                        <Title variant={'h6'}>Let’s get your profile set up</Title>
                        {steps.map((step, index) => {
                            const isActiveStep = index === activeStepIndex;
                            return (
                                <Step key={step.title}>
                                    {step.icon}
                                    <div>
                                        <Text
                                            variant={'body2'}
                                            medium
                                            color={isActiveStep ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                                        >
                                            {index + 1}. {step.title}
                                        </Text>
                                        <Text variant={'body2'} color={'GRAY'}>
                                            {step.subTitle}
                                        </Text>
                                    </div>
                                </Step>
                            );
                        })}
                    </div>

                    <ButtonContainer>
                        <StyledButton
                            variant={'secondary'}
                            onClick={steps[activeStepIndex]?.previousAction}
                            disabled={!steps[activeStepIndex]?.previousAction}
                        >
                            Previous
                        </StyledButton>
                        <StyledButton variant={'primary'} onClick={steps[activeStepIndex]?.saveAndContinueAction}>
                            Save & Continue
                        </StyledButton>
                    </ButtonContainer>
                </StyledSidebarContainer>

                {steps[activeStepIndex]?.component}
            </Body>

            <Toast
                headline={'Something went wrong'}
                description={`We can't submit this right now. Please try again later.`}
                open={errorToastOpen}
                onDismiss={() => setErrorToastOpen(false)}
                variant={'warning'}
                icon={ClockAlertIcon}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </Container>
    );
};
