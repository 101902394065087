import { LabsUtils } from '../../../labs/LabsUtils';
import type {
    BillingCreditCategories,
    Credit,
    PracticeInvoiceDetail,
    PracticeInvoiceItem,
} from '../../invoicing.types';
import {
    InvoiceItemCategoryBuckets,
    categoryFromCategoryEnum,
    useDescriptionForPaymentCallback,
} from '../../invoicing.utils';
import { ProcessingFeeTooltip } from '../ProcessingFeeTooltip';
import type { InvoiceItemRow } from './InvoiceTransactionsTable';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { LabsGqlInvoiceItemCategory } from '@orthly/graphql-schema';
import { LabsGqlPaymentStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';

type OrderItemSummary = { price: number; itemSummary: string };
type ItemSummary = { priceFormatted: string; itemSummary: string };

function getInvoiceItemOrderDescriptions(item: PracticeInvoiceItem, labOrder: LabsGqlOrder | undefined) {
    if (!labOrder) {
        const splits = _.compact(item.description?.split('-'));
        const formattedTotal = Format.currency(item.amount_cents, 'cents');

        return {
            display_amounts: [formattedTotal],
            descriptions: splits,
        };
    }
    const orderItemSummaries = labOrder.line_items.map<OrderItemSummary>(lineItem => {
        const totalForTeeth = _.sumBy(lineItem.teeth, t => t.amount_due_cents ?? 0);
        const totalForFields = _.sumBy(lineItem.preference_fields, t => t.price_cents ?? 0);
        return { itemSummary: LabsUtils.labelForLineItem(lineItem), price: totalForTeeth + totalForFields };
    });

    const groupedByTitles = _.groupBy<OrderItemSummary>(orderItemSummaries, i => `${i.price}${i.itemSummary}`);

    const itemSummaries = Object.values(groupedByTitles).flatMap<ItemSummary>(summaries => {
        const first = summaries[0];
        if (!first) {
            return [];
        }
        const priceFormatted = Format.currency(first.price, 'cents').replace('.00', '');
        return summaries.length > 1
            ? { priceFormatted: `${priceFormatted}/ea`, itemSummary: `${first.itemSummary} (x${summaries.length})` }
            : { priceFormatted, itemSummary: first.itemSummary };
    });

    const baseDescription = `${labOrder.patient.first_name} ${labOrder.patient.last_name}`;
    const formattedTotal = Format.currency(item.amount_cents, 'cents');

    return {
        display_amounts: [formattedTotal, ...itemSummaries.map(i => i.priceFormatted)],
        descriptions: [baseDescription, ...itemSummaries.map(i => i.itemSummary)],
    };
}

function getInvoiceItemNonOrderDescriptions(
    item: PracticeInvoiceItem,
    credits: Credit[],
    creditCategories: BillingCreditCategories,
    enableCreditAndRefundOverhaul?: boolean,
) {
    const creditsById = _.keyBy(credits, c => c.id);
    const creditCategoriesById = _.keyBy(creditCategories, c => c.id);
    const usedCredit = item.used_credit_id ? creditsById[item.used_credit_id] : undefined;
    const usedCreditCategory =
        usedCredit && usedCredit.credit_category_id ? creditCategoriesById[usedCredit.credit_category_id] : undefined;
    const baseDescription = item.description ?? '';
    const creditAttributionDescription = item.used_credit_attribution_description
        ? ` for ${item.used_credit_attribution_description}`
        : '';

    if (!enableCreditAndRefundOverhaul || !usedCreditCategory) {
        return [baseDescription];
    }

    return [`Credit${creditAttributionDescription}`, usedCreditCategory.name];
}

function sortAndFlattenGroupedInvoiceItems(items: InvoiceItemRow[]): InvoiceItemRow[] {
    // Attributed credits will have an attribution_key on them that will tie back to the
    // order_id or invoice_item_id of original item it is attributed to. Grouping them together
    // here so that we guarantee those items are next to each other in the list to be properly sorted
    const itemsGroupedByCreditAttributions = _.groupBy(items, item => {
        return item.used_credit_attribution_key || item.order_id || item.id;
    });

    return _.flatMap(itemsGroupedByCreditAttributions, group => {
        return group.sort((a, b) => {
            if (a.used_credit_attribution_key && !b.used_credit_attribution_key) {
                return 1; // The order/invoice item item should be first
            } else if (!a.used_credit_attribution_key && b.used_credit_attribution_key) {
                return -1; // The item's associated attributed credit should be second
            } else {
                return 0;
            }
        });
    });
}

export function useRowsFromInvoiceItems(
    items: PracticeInvoiceItem[],
    labOrders: LabsGqlOrder[],
    credits: Credit[],
    creditCategories: BillingCreditCategories,
): InvoiceItemRow[] {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const ordersById = _.keyBy(labOrders, p => p.id);
    const findOrderById = (order_id?: string | null): LabsGqlOrder | undefined =>
        order_id ? ordersById[order_id] : undefined;

    const rowsFromItems = items.map<InvoiceItemRow>(item => {
        // multi-location practices can see some information pertaining to another org's invoice/order
        // but won't have access to the full order. In this case, it's possible for the item to have an
        // order id, but not be able to load/view the order itself.
        const isLabOrderItem = !!item.order_id;
        const labOrder = findOrderById(item.order_id);

        const effective_date = (labOrder ? moment(labOrder.created_at) : moment(item.created_at)).toDate();
        const formattedTotal = Format.currency(item.amount_cents, 'cents');
        return {
            ...item,
            effective_date,
            mailing_address_id: labOrder?.mailing_address_id ?? item?.mailing_address_id ?? undefined,
            doctor_name: labOrder?.doctor_name ?? item?.doctor_name ?? undefined,
            ...(isLabOrderItem && item.amount_cents >= 0
                ? getInvoiceItemOrderDescriptions(item, labOrder)
                : {
                      display_amounts: [formattedTotal],
                      descriptions: getInvoiceItemNonOrderDescriptions(
                          item,
                          credits,
                          creditCategories,
                          enableCreditAndRefundOverhaul,
                      ),
                  }),
        };
    });
    // First we sort in descending order by effective_date
    const descendingSortedItems = _.sortBy(rowsFromItems, r => -r.effective_date.valueOf());
    // Then we sort by items and their attributed credits
    return sortAndFlattenGroupedInvoiceItems(descendingSortedItems);
}

export interface SummaryRow {
    // uses category, but table rows need an id
    id: string;
    quantity?: number | string;
    amountCents: number;
    type: 'balance' | 'category' | 'payment';
    tooltip?: React.ReactNode;
}

const rowCategoryOrderMapping: Record<InvoiceItemCategoryBuckets, number> = {
    [InvoiceItemCategoryBuckets.Orders]: 1,
    [InvoiceItemCategoryBuckets.UnmetMinimumCharge]: 2,
    [InvoiceItemCategoryBuckets.OtherCharges]: 3,
    [InvoiceItemCategoryBuckets.CreditsAndRefunds]: 4,
    [InvoiceItemCategoryBuckets.Prepayment]: 5,
    [InvoiceItemCategoryBuckets.CreditCardProcessingFee]: 6,
};

const getBalanceSummaryRows = (itemAndPaymentSummaries: SummaryRow[]): SummaryRow[] => {
    const remainingBalanceCents = _.sumBy(itemAndPaymentSummaries, t => t.amountCents);
    return [{ amountCents: remainingBalanceCents, id: 'Balance', type: 'balance' }];
};

export function isPaymentOrCreditRefundCategory(row: SummaryRow) {
    return row.type === 'payment' || row.id === InvoiceItemCategoryBuckets.CreditsAndRefunds;
}

export function useSummaryRows(invoice: PracticeInvoiceDetail, items: InvoiceItemRow[]): SummaryRow[] {
    const descriptionForPayment = useDescriptionForPaymentCallback();
    const { amount_paid } = invoice;
    const payments = invoice.payments.filter(payment => payment.status === LabsGqlPaymentStatus.Succeeded);

    const convertCategoryToSummaryRow = (category: string, items: InvoiceItemRow[]): SummaryRow => {
        const total = _.sumBy(items, i => i.amount_cents);
        const idFromCategory: InvoiceItemCategoryBuckets = categoryFromCategoryEnum(
            category as LabsGqlInvoiceItemCategory,
        );
        return {
            id: idFromCategory,
            amountCents: total,
            quantity: items.length,
            type: 'category',
            tooltip:
                idFromCategory === InvoiceItemCategoryBuckets.CreditCardProcessingFee ? <ProcessingFeeTooltip /> : null,
        };
    };

    // keep category sort consistent according to rowCategoryOrderMapping
    const sortByCategory = (a: SummaryRow, b: SummaryRow) =>
        rowCategoryOrderMapping[a.id as InvoiceItemCategoryBuckets] -
        rowCategoryOrderMapping[b.id as InvoiceItemCategoryBuckets];

    const byCategory = _.groupBy(
        _.sortBy(items, i => i.category),
        i => i.category,
    );

    // for each category, get the total amount (cents) and quantity of orders
    const itemCategorySummaries = Object.entries(byCategory)
        .map<SummaryRow>(([category, items]) => convertCategoryToSummaryRow(category, items))
        .sort(sortByCategory);

    // for each payment, load the appropriate description based on payment method, dr, prefs, etc.
    const paymentSummaries = payments.map<SummaryRow>(payment => {
        const id = descriptionForPayment(payment);
        return { id, amountCents: -payment.amount_cents, type: 'payment' };
    });

    // if the invoice is marked as paid, but there are no payments, add a generic payment summary
    if (amount_paid > 0 && paymentSummaries.length === 0) {
        paymentSummaries.push({ id: 'Payment', amountCents: -amount_paid, type: 'payment' });
    }

    const itemAndPaymentSummaries = [...itemCategorySummaries, ...paymentSummaries];
    const balanceRows = getBalanceSummaryRows(itemAndPaymentSummaries);
    return [...itemAndPaymentSummaries, ...balanceRows];
}
