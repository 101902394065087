import { useSession } from '@orthly/session-client';
import type { StatsigExperiments } from '@orthly/shared-types';
import type { ExperimentEvaluationOptions, Experiment } from '@statsig/client-core';
import { useStatsigClient, StatsigProvider, useClientAsyncInit, useStatsigUser } from '@statsig/react-bindings';
import React from 'react';

export { useStatsigClient, useLayer } from '@statsig/react-bindings';

export const ANONYMOUS_STATSIG_USER = { userID: 'anonymous' };

const STATSIG_KEY = process.env.STATSIG_SDK_KEY || 'TEST_SDK_KEY';

export const useExperiment = (
    experimentName: keyof StatsigExperiments,
    options?: ExperimentEvaluationOptions,
): Experiment => {
    const { client } = useStatsigClient();

    return client.getExperiment(experimentName, options);
};

type StatsigDandyUser = {
    userID: string;
    custom: Record<string, string | number | boolean>;
    customIDs?: Record<string, string>;
};

const useSessionUser = () => {
    const session = useSession();
    if (!session?.user_id) {
        return ANONYMOUS_STATSIG_USER;
    }

    const user: StatsigDandyUser = {
        userID: session.user_id,
        custom: {
            organization_id: session?.organization_id || 'NA',
            organization_type: session?.organization_type || 'UNKNOWN',
        },
        customIDs: {
            organization_id: session?.organization_id || 'NA',
        },
    };
    if (session.roles) {
        session.roles.forEach(role => {
            user.custom[role] = true;
        });
    }
    if (session.activated_at) {
        user.custom.activated_at = session.activated_at.toISOString();
    }
    if (session.created_at) {
        user.custom.created_at = session.created_at.toISOString();
    }
    return user;
};

const UpdateUserGuard: React.FC = ({ children }) => {
    const { updateUserAsync } = useStatsigUser();
    const user = useSessionUser();

    React.useEffect(() => {
        void updateUserAsync(user);
        // We explicitly only change this if the userId changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userID]);

    return children as React.ReactElement;
};

export const DandyStatsigProvider: React.FC<{ fallback?: React.ComponentType }> = ({
    children,
    fallback: Fallback,
}) => {
    const user = useSessionUser();

    const { client, isLoading } = useClientAsyncInit(STATSIG_KEY, user);

    if (Fallback && isLoading) {
        return <Fallback />;
    }

    return (
        <StatsigProvider client={client}>
            <UpdateUserGuard>{children}</UpdateUserGuard>
        </StatsigProvider>
    );
};
