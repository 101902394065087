import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')<{ mediaRight: boolean; height: string }>(({ mediaRight, height }) => ({
    display: 'flex',
    flexDirection: mediaRight ? 'row' : 'row-reverse',
    border: '1px solid #00000014',
    borderRadius: '16px',
    padding: '16px',
    backgroundColor: FlossPalette.WHITE,
    height: height,
    width: '1096px',
}));
const Body = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '48px',
    justifyContent: 'space-between',
    gap: '32px',
});
const MainBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
});
const Actions = styled('div')({
    display: 'flex',
    gap: '24px',
});

const MediaSection = styled('div')({
    display: 'flex',
});

interface OnboardingStepCardProps {
    mediaRight?: boolean;
    media: React.ReactNode;
    height?: string;
    title: string;
    bodyText: string[];
    actions: React.ReactNode[];
}

export const OnboardingStepCard: React.VFC<OnboardingStepCardProps> = ({
    mediaRight = false,
    media,
    height = 'auto',
    title,
    bodyText,
    actions,
}) => {
    return (
        <Container mediaRight={mediaRight} height={height}>
            <Body>
                <MainBody>
                    <Text variant={'h5'} medium>
                        {title}
                    </Text>
                    {bodyText.map(text => (
                        <Text key={text} variant={'body2'} color={'GRAY'}>
                            {text}
                        </Text>
                    ))}
                </MainBody>
                <Actions>{actions}</Actions>
            </Body>
            <MediaSection>{media}</MediaSection>
        </Container>
    );
};
