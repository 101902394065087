import { OnboardingStep } from './CreateAccount';
import { OnboardingStepCard } from './OnboardingStepCard';
import { OnboardingStepLayout } from './OnboardingStepLayout';
import { Button, styled } from '@orthly/ui-primitives';
import React from 'react';

interface WelcomeScreenProps {
    setStep(step: OnboardingStep): void;
}

const ImageStyled = styled('img')({
    maxWidth: '447px',
    objectFit: 'contain',
});
const ButtonStyled = styled(Button)({
    width: 'fit-content',
});

export const WelcomeScreen: React.VFC<WelcomeScreenProps> = ({ setStep }) => {
    return (
        <OnboardingStepLayout id={'welcome-step'} title={'Welcome to Dandy'}>
            <OnboardingStepCard
                mediaRight
                media={<ImageStyled src={'/onboarding/HomeActivaton.png'} alt={'Home Activation'} />}
                height={'319px'}
                title={'First, let’s set up your profile'}
                bodyText={[
                    'Setting up your profile lets you manage orders, staff members, handle invoices, set up notifications, and so much more. This process will take roughly 5 minutes.',
                ]}
                actions={[
                    <ButtonStyled
                        key={'set-up-profile'}
                        data-test={'set-up-profile'}
                        onClick={() => setStep(OnboardingStep.ProfileSetUp)}
                        variant={'primary'}
                        endIcon={'ChevronRight'}
                    >
                        Set Up Profile
                    </ButtonStyled>,
                ]}
            />
        </OnboardingStepLayout>
    );
};
