import { useGetUserFromDrPrefId } from '../../graphql/useGetUserFromDrPrefId.graphql';
import { CreateLoginCredentials } from '../pre-auth/screens/create-login-credentials/CreateLoginCredentials';
import { useNextStep } from '../pre-auth/screens/create-login-credentials/useNextStep';
import { useDoctorId } from '../pre-auth/state/useDoctorId';
import { OnboardingHeader } from './OnboardingHeader';
import { WelcomeScreen } from './WelcomeScreen';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-schema';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: FlossPalette.TAN,
    height: '100%',
    width: '100%',
});

export enum OnboardingStep {
    Welcome = 'welcome',
    ProfileSetUp = 'profileSetUp',
    Default = 'default',
}

const getStepComponent = ({
    step,
    setStep,
    preferencesId,
    user,
}: {
    step: OnboardingStep;
    setStep: (step: OnboardingStep) => void;
    preferencesId: string;
    user?: LabsGqlUserDtoFragment | undefined;
}) => {
    switch (step) {
        case OnboardingStep.Welcome:
            return (
                <Container>
                    <OnboardingHeader />
                    <WelcomeScreen setStep={setStep} />
                </Container>
            );
        case OnboardingStep.ProfileSetUp:
            return <CreateLoginCredentials preferencesId={preferencesId} user={user} />;
        default:
            return null;
    }
};

const useRemoveImpersonateToken = () => {
    const history = useHistory();

    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('impersonateToken')) {
        searchParams.delete('impersonateToken');
        history.replace({ search: searchParams.toString() });
    }
};

const useRedirect = (isAccountActivated?: boolean, loading?: boolean) => {
    const goToNextStep = useNextStep();

    React.useEffect(() => {
        if (!loading && isAccountActivated) {
            goToNextStep();
        }
    }, [isAccountActivated, goToNextStep, loading]);
};

export const CreateAccount: React.VFC = () => {
    const { value: enableGuidedOnboarding, loading: enableGuidedOnboardingLoading } =
        useFeatureFlag('enableGuidedOnboarding');
    const [step, setStep] = React.useState(OnboardingStep.Default);

    useRemoveImpersonateToken();

    const preferencesId = useDoctorId();
    const { user, loading: loadingUser } = useGetUserFromDrPrefId(preferencesId);
    useRedirect(user?.is_account_activated, loadingUser);

    const StepComponent = getStepComponent({ step, setStep, preferencesId, user });

    React.useEffect(() => {
        if (!enableGuidedOnboardingLoading) {
            setStep(enableGuidedOnboarding ? OnboardingStep.Welcome : OnboardingStep.ProfileSetUp);
        }
    }, [enableGuidedOnboarding, enableGuidedOnboardingLoading]);

    return <>{StepComponent}</>;
};
