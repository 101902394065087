import { useCurrentProfile } from '../../redux';
import { InboxBackgroundImg } from './components/InboxBackgroundImg';
import { InboxEmptySection } from './components/InboxEmptySection';
import { InboxLoyaltyBanner } from './components/tracker/InboxLoyaltyBanner';
import { InboxTrackingRoot } from './components/tracker/InboxTrackingRoot.graphql';
import { useCrmPractice } from './state/useCRMPractice';
import { useInboxTitleMessage } from './useInboxTitleMessage';
import { useQuery } from '@apollo/client';
import { LabsGqlOrderSearchPracticeSortField, LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { LoadBlocker, TOOLBAR_TABLET_DOWN_HEIGHT, ToolbarContainer } from '@orthly/ui';
import { stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import { useFeatureFlag, VeneerPracticeOrderListViewIds_Query } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: TOOLBAR_TABLET_DOWN_HEIGHT,
        },
    },
    toolbar: {
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            top: TOOLBAR_TABLET_DOWN_HEIGHT,
        },
    },
}));

const useLoyaltyDetails = (): { optedIn: boolean; points: number } | null => {
    const { data: rawCRMPracticeData } = useCrmPractice();

    if (!rawCRMPracticeData?.getCRMPractice?.nullable_loyalty_details) {
        return null;
    }

    const details = rawCRMPracticeData.getCRMPractice.nullable_loyalty_details;
    return { optedIn: details.has_opted_into_loyalty, points: details.loyalty_points_balance };
};

const InboxContentToolbar: React.VFC = () => {
    const title = useInboxTitleMessage();
    const classes = useStyles();
    const { value: enableInboxLoyaltyBanner = false } = useFeatureFlag('enableInboxLoyaltyBanner');
    const loyaltyDetails = useLoyaltyDetails();

    return (
        <ToolbarContainer sticky disableMobileSticky className={classes.toolbar}>
            {enableInboxLoyaltyBanner && loyaltyDetails ? (
                <InboxLoyaltyBanner isOptedIn={loyaltyDetails.optedIn} points={loyaltyDetails.points} />
            ) : (
                <Text variant={'h5'} data-test={'inbox-title'}>
                    {title}
                </Text>
            )}
        </ToolbarContainer>
    );
};

export const InboxContentRoot: React.FC = () => {
    const classes = useStyles();

    const doctorProfile = useCurrentProfile();

    const [lastIdFetchAt, setLastIdFetchAt] = React.useState(new Date().valueOf());
    const { data, loading, refetch } = useQuery(VeneerPracticeOrderListViewIds_Query, {
        variables: {
            args: {
                doctor_preferences_ids: doctorProfile ? [doctorProfile.id] : [],
                sort_by: LabsGqlOrderSearchPracticeSortField.Relevancy,
                sort_asc: true,
                status: LabsGqlPracticeOrderStatus.All,
                limit: 25,
            },
        },
        onCompleted: () => {
            setLastIdFetchAt(new Date().valueOf());
        },
    });

    const totalOrders = data?.practiceSearchOrderIds.length ?? 0;

    return (
        <Grid container className={classes.root}>
            <Grid style={{ width: '100%' }}>
                <InboxContentToolbar />
                <LoadBlocker blocking={loading} loader={'dandy'}>
                    <InboxEmptySection activeOrderCount={totalOrders} />
                    <InboxTrackingRoot
                        orderIds={data?.practiceSearchOrderIds.map(i => i.id) ?? []}
                        title={'Track your orders'}
                        lastIdFetchAt={lastIdFetchAt}
                        refetch={async () => {
                            await refetch();
                        }}
                    />
                </LoadBlocker>
            </Grid>
            <InboxBackgroundImg activeOrderCount={totalOrders} />
        </Grid>
    );
};
