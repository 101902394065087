/*
The contents of this file came from a refactoring to remove
cyclic/circular dependencies.
*/
import type {
    LabsGqlDoctorDeliveryAddressFragment,
    LabsGqlDoctorPreferencesFragment,
    LabsGqlLabOrderFragment,
    LabsGqlScanFileCheckoutFragment,
} from '@orthly/graphql-operations';
import type {
    LabsGqlCartSlaModifier,
    LabsGqlLabOrderPhotoType,
    LabsGqlLabOrderSinglePhotoInput,
    LabsGqlOrderRefabReasonInput,
    LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import type { ICartItemV2DTO } from '@orthly/items';

export enum DoctorNotesCategoryV2 {
    None = 'None',
    OrderDetails = 'OrderDetails',
    Attachments = 'Attachments',
    ExtraInstructions = 'ExtraInstructions',
}

// Currently unused
export interface CheckoutFeatureFlags {}

type ImplantScreen = 'instructions' | 'upload' | 'scanbodies';

export interface ImplantCheckoutScanbodiesState {
    /** Current index into tooth group array */
    currentToothIndex: number;
    /** Whether the switch into this screen was from clicking "back" or "next" */
    motion: 'back' | 'next';
}

export interface ImplantCheckoutState {
    allowInstructions: boolean;
    missingUploadWarningShowing: boolean;
    scanbodiesScreen: ImplantCheckoutScanbodiesState;
    screen: ImplantScreen;
}

export type CheckoutStep = 0 | 1 | 2;

export type CheckoutStepTwoScreen =
    | 'aestheticPhotoUpload'
    | 'implantImages'
    | 'smileStyle'
    | 'refab'
    | 'summary'
    | 'surgicalGuideCbctUpload';

export type CheckoutItemV2 = ICartItemV2DTO & {
    bulk_edit_id: string;
    from_scan_export: boolean;
    item_index: number;
    original_material?: string;
};

export interface CheckoutRefabState {
    attachments: string[];
    attachmentsUploading?: boolean;
    original_order: LabsGqlLabOrderFragment;
    notes?: string;
    reasons: LabsGqlOrderRefabReasonInput[];
}

export interface SurgicalGuideCheckoutState {
    cbct_url: string | null;
}

type AestheticScreen = 'instructions' | 'upload';

export interface AestheticCheckoutState {
    allowInstructions: boolean;
    missingUploadWarningShowing: boolean;
    photos: { [key in LabsGqlLabOrderPhotoType]?: string };
    // Photo upload screen management stuff
    screen: AestheticScreen;
}

export interface CheckoutState {
    aestheticCheckout: AestheticCheckoutState;
    bulkEditIds: string[];
    canRegroupBulkIds: string[];
    implantCheckout: ImplantCheckoutState;
    // For step === 1, the index of the current screen in getItemScreens(state)
    itemScreenIndex: number;
    step: CheckoutStep;
    stepTwoScreen: CheckoutStepTwoScreen;
    smileStyle?: LabsGqlSmileStyleChoice;
    existingOrderWarningDismissed: boolean;
    scan?: LabsGqlScanFileCheckoutFragment;
    doctor?: LabsGqlDoctorPreferencesFragment;
    address?: LabsGqlDoctorDeliveryAddressFragment;
    items: CheckoutItemV2[];
    removed_scan_items: CheckoutItemV2[];
    doctor_notes?: string;
    doctorNotesOpen: boolean;
    doctorNotesV2Category: DoctorNotesCategoryV2;
    attachments: LabsGqlLabOrderSinglePhotoInput[];
    patient_first_name?: string;
    patient_last_name?: string;
    patient_birthday?: Date;
    patient_gender?: string;
    is_training_order: boolean;
    refab: CheckoutRefabState | undefined;
    waxupState: { selected: boolean; tooltipDismissed: boolean };
    suggestedItemState?: 'rendered' | 'selected';
    surgicalGuideCheckout: SurgicalGuideCheckoutState;
    featureFlags: CheckoutFeatureFlags;
    sla_modifier: LabsGqlCartSlaModifier | undefined;
}
