import { PaddedColumnGridLayout } from './TrainingDataIntake.styles';
import { TrainingDialogStage } from './TrainingDataIntake.types';
import { TrainingDataIntakeForm } from './TrainingDataIntakeForm';
import { useCreateTrainingIntakeSurveyResponse } from './hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import { useCanSubmitTrainingData, useOnSubmitTrainingDataAction } from './hooks/useSubmitTrainingData';
import { useTrainees } from './hooks/useTrainees';
import { Button, Dialog, FlossPalette, Icon, IconButton, styled, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

const FormWrapper = styled('div')(({ theme }) => {
    const spacing = theme.spacing(3);
    if (spacing.toString().includes('px')) {
        return {
            padding: `0 ${spacing}`,
        };
    }
    return {
        padding: `0 ${spacing}px`,
    };
});

const BottomLayout = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
}));

const ButtonsLayout = styled(PaddedColumnGridLayout)({
    width: '100%',
    marginBottom: 4,
    gridAutoColumns: '1fr',
});

const CloseButton: React.VFC<{ onClick: () => void }> = props => (
    <IconButton size={'small'} sx={{ position: 'absolute', top: 8, right: 8 }} {...props}>
        <Icon icon={'CloseIcon'} color={'primary'} />
    </IconButton>
);

const useTitles = (stage: TrainingDialogStage) => {
    const { trainees, dentists } = useTrainees();

    if (stage === TrainingDialogStage.FIRST) {
        return {
            title: 'Your practice’s training session',
            description:
                'Please fill out this form for each person who plans on attending Launch Training. This will help us tailor the training to your staff’s current scanning experience.',
            nextButtonText: dentists.length > 0 ? 'Continue' : 'Submit',
            hasTopBorder: trainees.length > 1,
        };
    }

    return {
        title: 'Retraction methods, prep styles, & more',
        description: 'Help us make your training session as effective as possible.',
        nextButtonText: 'Submit',
        hasTopBorder: dentists.length > 1,
    };
};

const useOnCloseAction = (setOpen: (open: boolean) => void, setStage: (stage: TrainingDialogStage) => void) => {
    return () => {
        setOpen(false);
        setStage(TrainingDialogStage.FIRST);
    };
};

const useOnBackAction = (
    setOpen: (value: boolean) => void,
    setStage: (stage: TrainingDialogStage) => void,
    stage: TrainingDialogStage,
) => {
    const onCloseAction = useOnCloseAction(setOpen, setStage);

    if (stage === TrainingDialogStage.FIRST) {
        return onCloseAction;
    }

    return () => {
        setStage(TrainingDialogStage.FIRST);
    };
};

interface TrainingDataIntakeDialogProps {
    refetchTrainingData: () => void;
}

export const TrainingDataIntakeDialog: React.VFC<TrainingDataIntakeDialogProps> = ({ refetchTrainingData }) => {
    const { open, setOpen, submitting } = useCreateTrainingIntakeSurveyResponse();
    const isMobile = useScreenIsMobile();
    const [stage, setStage] = React.useState<TrainingDialogStage>(TrainingDialogStage.FIRST);
    const { title, description, nextButtonText, hasTopBorder } = useTitles(stage);

    const canSubmit = useCanSubmitTrainingData(stage);
    const onSubmit = useOnSubmitTrainingDataAction({ stage, setStage, refetch: refetchTrainingData });
    const onCloseAction = useOnCloseAction(setOpen, setStage);
    const onBackAction = useOnBackAction(setOpen, setStage, stage);

    return (
        <Dialog
            open={open}
            onClose={onCloseAction}
            fullScreen={isMobile}
            PaperProps={{ style: { width: 500, borderRadius: 16 } }}
        >
            <FormWrapper>
                <Text variant={'body1'} medium style={{ marginTop: 24 }}>
                    {title}
                </Text>

                {isMobile && <CloseButton onClick={onCloseAction} />}

                <Text variant={'body2'} color={'GRAY'} style={{ margin: '8px 0 16px 0' }}>
                    {description}
                </Text>
                <TrainingDataIntakeForm stage={stage} />
            </FormWrapper>

            <BottomLayout style={{ borderTop: hasTopBorder ? `1px solid ${FlossPalette.STROKE_LIGHT}` : 'none' }}>
                <ButtonsLayout>
                    <Button variant={'secondary-gray'} onClick={onBackAction} disabled={submitting}>
                        Back
                    </Button>
                    <Button variant={'primary'} onClick={onSubmit} disabled={!canSubmit || submitting}>
                        {nextButtonText}
                    </Button>
                </ButtonsLayout>
                <Text variant={'caption'} color={'GRAY'}>
                    Fields marked with * are required to proceed.
                </Text>
            </BottomLayout>
        </Dialog>
    );
};
