import { PartnerAppRoot } from './PartnerAppRoot';
import { PerhapsYouMeantToBeSomewhereElse } from './PerhapsYouMeantToBeSomewhereElse';
import { AnalyticsClient } from './analytics/analyticsClient';
import { FEATURE_FLAGS_ROUTE, NOTIFICATION_PREFERENCES_ROUTE, PATIENT_PORTAL_ROUTE } from './routes';
import { StandaloneChatWindowRoot } from './screens/chat/ChatWindow/StandaloneChatWindowRoot';
import { ZendeskChatSessionWrapper } from './screens/chat/ZendeskChat';
import { CHAT_FRAME_ROUTE, KUSTOMER_FRAME_ROUTE, CST_FRAME_ROUTE } from './screens/chat/chat-routes.constant';
import { ScannerSubmit } from './screens/checkout-v2/ScannerSubmit.graphql';
import { SCANNER_SUBMIT_ROUTE } from './screens/checkout-v2/state/CheckoutPaths';
import { LoggedOutRoot } from './screens/logged-out/LoggedOutRoot';
import { SignUpLoggedOutRoot } from './screens/logged-out/SignUpLoggedOutRoot';
import { NotificationsRoot } from './screens/notifications/NotificationsRoot';
import { CreateAccount } from './screens/onboarding/CreateAccount';
import { PatientAppLoading, PatientAppRoot } from './screens/patient/PatientAppRoot';
import { AddPhoneNumber } from './screens/pre-auth/screens/add-phone-number';
import { ConfigService } from './utils';
import { AnonymousGqlProvider } from './utils/AnonymousGqlProvider';
import { ScannerTokenSessionLoader } from './utils/ScannerTokenSessionLoader';
import { getManufacturerId } from './utils/authorization';
import { useInitChairside, useIsChairside } from './utils/chairside.hooks';
import './utils/dayjs-utc';
import { HotKeyProvider, PracticeScreen } from '@orthly/dentin';
import { SessionGuard, SessionGuardUtils, useSession, encodeRedirectToSearchParamString } from '@orthly/session-client';
import {
    AppContainer,
    FeatureFlagOverridesEditor,
    InfraUpdateTools,
    LaunchDarklyProvider,
    useLaunchDarklyUserContext,
} from '@orthly/veneer';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

const ENABLE_FEATURE_FLAG_OVERRIDES = process.env.ENABLE_FEATURE_FLAG_OVERRIDE;

const AppLoggedIn: React.VFC = () => {
    const session = useSession();
    const manufacturerId = getManufacturerId(session);

    if (manufacturerId) {
        return <PerhapsYouMeantToBeSomewhereElse />;
    }

    return <PartnerAppRoot />;
};

export const App: React.VFC = () => {
    const ldContext = useLaunchDarklyUserContext();
    const location = useLocation();
    const [originalLocation] = React.useState<LocationDescriptorObject>({ ...location });
    const isChairside = useIsChairside();

    const session = useSession();
    React.useEffect(() => {
        // We only identify here for labs because practices are identified when they select a user via
        // the Netflix-style login menu.
        // This method of identifying labs is identical to how we identify users in the ops portal.
        if (session?.organization_type === 'lab') {
            AnalyticsClient.identify(session.user_id, {
                firstName: session.user.first_name,
                lastName: session.user.last_name,
                email: session.user.email || undefined,
                roles: session.roles,
            });
        }
    }, [session]);

    useInitChairside();

    return (
        <LaunchDarklyProvider config={ConfigService} context={ldContext}>
            <AppContainer disableUpdateNotification={isChairside}>
                <InfraUpdateTools />
                <HotKeyProvider>
                    <Switch>
                        <Route path={PATIENT_PORTAL_ROUTE}>
                            <AnonymousGqlProvider loading={<PatientAppLoading />}>
                                <PatientAppRoot />
                            </AnonymousGqlProvider>
                        </Route>
                        <Route path={[KUSTOMER_FRAME_ROUTE, CHAT_FRAME_ROUTE]}>
                            <ScannerTokenSessionLoader>
                                <ZendeskChatSessionWrapper />
                            </ScannerTokenSessionLoader>
                        </Route>
                        <Route path={CST_FRAME_ROUTE}>
                            <ScannerTokenSessionLoader>
                                <StandaloneChatWindowRoot />
                            </ScannerTokenSessionLoader>
                        </Route>
                        <Route path={SCANNER_SUBMIT_ROUTE}>
                            <ScannerSubmit />
                        </Route>
                        <Route path={`/${PracticeScreen.login}`}>
                            <LoggedOutRoot originalLocation={originalLocation} />
                        </Route>
                        <Route exact path={`/${PracticeScreen.sign_up_verify}`}>
                            <SignUpLoggedOutRoot originalLocation={originalLocation} />
                        </Route>
                        <Route path={NOTIFICATION_PREFERENCES_ROUTE}>
                            <NotificationsRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.add_phone}`}>
                            <SessionGuard test={SessionGuardUtils.sessionExists} fallback={<Redirect to={`/login`} />}>
                                <AddPhoneNumber />
                            </SessionGuard>
                        </Route>
                        <Route path={`/${PracticeScreen.create_account}`}>
                            <SessionGuard test={SessionGuardUtils.sessionExists} fallback={<Redirect to={`/login`} />}>
                                <CreateAccount />
                            </SessionGuard>
                        </Route>
                        {ENABLE_FEATURE_FLAG_OVERRIDES && (
                            <Route path={FEATURE_FLAGS_ROUTE} component={FeatureFlagOverridesEditor} />
                        )}
                        <Route path={`/*`}>
                            <SessionGuard
                                test={SessionGuardUtils.sessionExists}
                                fallback={
                                    <Redirect
                                        to={`/login${encodeRedirectToSearchParamString(window.location, 'inbox')}`}
                                    />
                                }
                            >
                                <AppLoggedIn />
                            </SessionGuard>
                        </Route>
                    </Switch>
                </HotKeyProvider>
            </AppContainer>
        </LaunchDarklyProvider>
    );
};
