import logo from '../../assets/logo/LogoDark.svg';
import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../utils/chairside.hooks';
import { ReturnLink } from '../pre-auth/components/page-layout/PageLayout.styles';
import { ProgressBar } from './ProgressBar';
import { PracticeScreen } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px 56px',
    backgroundColor: FlossPalette.TAN,
});
const ImageStyled = styled('img')({
    width: 88,
    height: 32,
});
const ButtonStyled = styled(Button)({
    gap: '10px',
});
const SkipSetupContentContainer = styled('div')({
    padding: '32px 0',
});
const SkipSetUpActionsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const RightButton: React.VFC = () => {
    const quitChairside = usePortalToChairsideBridgeCommand('quitChairside');
    const isChairside = useIsChairside();
    const history = useHistory();
    const [isExitSetupDialogOpen, setIsExitSetupDialogOpen] = React.useState(false);

    if (isChairside) {
        return (
            <ButtonStyled
                variant={'secondary'}
                onClick={() => {
                    quitChairside?.();
                }}
            >
                <Icon icon={'PowerSettingsNewOutlined'} />
                Close Dandy Chairside
            </ButtonStyled>
        );
    }

    if (history.location.pathname === `/${PracticeScreen.onboarding}`) {
        return (
            <RootActionDialog
                loading={false}
                open={isExitSetupDialogOpen}
                setOpen={setIsExitSetupDialogOpen}
                showCloseButton
                title={'Skip setup'}
                content={
                    <>
                        <SkipSetupContentContainer>
                            <Text variant={'body2'} color={'GRAY'}>
                                You are about to skip the entire process. You can always come back to this later via
                                settings or update any pages such as billing, manage staff members, and more using the
                                Practice Portal.
                            </Text>
                        </SkipSetupContentContainer>
                        <SkipSetUpActionsContainer>
                            <Button onClick={() => setIsExitSetupDialogOpen(false)} variant={'secondary'}>
                                Go Back
                            </Button>
                            <Button onClick={() => history.push(PracticeScreen.inbox)} variant={'primary'}>
                                Skip Setup
                            </Button>
                        </SkipSetUpActionsContainer>
                    </>
                }
                CustomButton={() => (
                    <Button variant={'ghost'} inline onClick={() => setIsExitSetupDialogOpen(true)}>
                        Exit Setup
                    </Button>
                )}
            />
        );
    }

    return (
        <ReturnLink href={'https://www.meetdandy.com/'}>
            <Text bold color={'PRIMARY_FOREGROUND'} variant={'body2'}>
                Return to meetdandy.com
            </Text>
        </ReturnLink>
    );
};

interface OnboardingHeaderProps {
    showProgressBar?: boolean;
    percentComplete?: number;
}

export const OnboardingHeader: React.VFC<OnboardingHeaderProps> = ({
    showProgressBar = false,
    percentComplete = 0,
}) => {
    return (
        <Header>
            <ImageStyled alt={'Dandy logo'} src={logo} />
            {showProgressBar && <ProgressBar percentComplete={percentComplete} />}
            <RightButton />
        </Header>
    );
};
